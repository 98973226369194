// CARGAR EL PDF EN CANVAS



window.convertDataURIToBinary = function(dataURI) {
    var BASE64_MARKER = ';base64,';
    var base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
    var base64 = dataURI.substring(base64Index);
    var raw = window.atob(base64);
    var rawLength = raw.length;
    var array = new Uint8Array(new ArrayBuffer(rawLength));

    for(var i = 0; i < rawLength; i++) {
        array[i] = raw.charCodeAt(i);
    }
    return array;
}

window.cargarpdf = function (base64,pagina = 1, funcLoaded = null, scale = 0.5, zxx = -1, zxy = -1,zw = 200,zh = 35) {
    var target = "canvas_pdf_custom",signing = true,tipo_pagina = 2;
    //tipo pagina 1 = primera pagina,2 = cualquier pagina, -1 =  ultima pagina

    $("#procesar_firma_visual").addClass("disabled");
    $(".limpiarFirma").addClass("hide")
    $("#document_pdf_custom").css("margin-top","-35px");
    $(".imgFirma").addClass("hide")
    //var load = document.getElementById("load_canvas_pdf_custom")
    //load.style.display = "block"
    var canvas = document.getElementById("canvas_pdf_custom");
    var context = canvas.getContext('2d');
    context.clearRect(0, 0, canvas.width, canvas.height);
    canvas.style.display = "none"
    //
    // The workerSrc property shall be specified.
    //

    // Asynchronous download PDF
    //
    try {
        var pdfAsDataUri = "data:application/pdf;base64," + base64; // shortened
        var pdfAsArray = convertDataURIToBinary(pdfAsDataUri);
        var loadingTask = pdfjsLib.getDocument(pdfAsArray);
        loadingTask.promise.then(function (pdf) {
            //
            // Fetch the first page
            //
            var page_preview = 1;

            if (tipo_pagina) {
                tipo_pagina = parseInt(tipo_pagina)
                if (tipo_pagina == 2)
                    page_preview = parseInt(pagina);
                else if (tipo_pagina == 1)
                    page_preview = 1;
                else if (tipo_pagina == -1)
                    page_preview = pdf.numPages;

            }
            if (funcLoaded)
                funcLoaded(pdf.numPages)
            pdf.getPage(page_preview).then(function (page) {

                var viewport = page.getViewport({scale:scale});
                //
                // Prepare canvas using PDF page dimensions
                //load.style.display = "none"
                try{
                    var data = {
                        operacion: "pdf_cargado"
                    }
                    json = (JSON.stringify(data));
                    try {
                        invokeCSharpAction(json);
                    }catch(e){
                        window.chrome.webview.postMessage(json);
                    }
                }catch{

                }

                canvas.style.display = "block"
                canvas.height = viewport.height;
                canvas.width = viewport.width;

                if(zxx == -1)
                    $(".imgFirma").removeClass("hide")

                //
                // Render PDF page into canvas context
                //
                var renderContext = {
                    canvasContext: context,
                    viewport: viewport,
                };
                page.render(renderContext).promise.then(function () {

                    if (signing) {
                        var data = canvas.toDataURL();
                        Canvas(data, target, "" ,scale,zxx,zxy,zw,zh)
                    }
                });

            });

        });
    } catch (e) {
        canvas.style.display = "none";
        console.log(e)
        alert(e);
    }


}


// RENDERIZA EL PDF EN EL CANVAS
window.Canvas = function (data, target, image, scale, zxx, zxy,zwidth = 200, zheight = 35) {
    var canvas = document.getElementById(target);
    var g = canvas.getContext('2d');
    var down = false;
    var $canvas = $("#" + target);
    var canvasOffset = $canvas.offset();
    var offsetX = canvasOffset.left;
    var offsetY = canvasOffset.top;
    var scrollX = $canvas.scrollLeft();
    var scrollY = $canvas.scrollTop();
    var startX;
    var startY;
    var selectedImage = null;
    var $tools = $(".imgFirma");

    // make all .tool's draggable
    $tools.draggable({
        helper: 'clone',
    });

    $(".limpiarFirma").on('click', function () {
        var pdfImg = new Image();
        pdfImg.src = data;
        g.clearRect(0, 0, canvas.width, canvas.height);
        setTimeout(function () {
            g.drawImage(pdfImg, 0, 0);
        }, 50);

        window.firma_desposicionada = true;
        image.x = 0;
        image.y = 0;
        $(".limpiarFirma").addClass("hide")
        $("#document_pdf_custom").css("margin-top","-35px");
        $(".imgFirma").removeClass("hide");
    });


// assign each .tool its index in $tools
    $tools.each(function (index, element) {
        $(this).data("toolsIndex", index);
    });


// make the canvas a dropzone
    $canvas.droppable({
        drop: dragDrop,
    });

    var width_rectangle = zwidth / (1 / scale);
    var height_rectangle = zheight / (1 / scale);
// handle a drop into the canvas
    function dragDrop(e, ui) {

        // get the drop point (be sure to adjust for border)
        var rect = canvas.getBoundingClientRect(),
            offsetX = rect.left ,
            offsetY = rect.top;

        x = parseInt(ui.offset.left - offsetX) - 1;
        y = parseInt(ui.offset.top - offsetY);
        width = ui.helper[0].width ;
        height = ui.helper[0].height;

        mouseX = parseInt(e.clientX - offsetX);
        mouseY = parseInt(e.clientY - offsetY);

        // drawImage at the drop point using the dropped image
        // This will make the img a permanent part of the canvas content


        var theIndex = ui.draggable.data("toolsIndex");
        image.url = $tools[theIndex].src;

        startX = mouseX;
        startY = mouseY;
        image.x = x;
        image.y = y;
        if(!(image.x == 0 && image.y == 0)){
            $("#document_pdf_custom").css("margin-top","0px");
            $(".limpiarFirma").removeClass("hide")
        }
        // drawImage at the drop point using the dropped image
        // This will make the img a permanent part of the canvas content

        $tools[theIndex].width = width_rectangle ;
        $tools[theIndex].height = height_rectangle;
        g.drawImage($tools[theIndex], x, y, width_rectangle, height_rectangle);

        image.x_escalado = Math.floor(image.x)* (1 / scale);
        image.y_escalado = Math.floor(canvas.height - image.y - height_rectangle)* (1 / scale);

        $("#sign_x").val(image.x_escalado)
        $("#sign_x").attr("x",image.x)
        $("#sign_y").val(image.y_escalado)
        $("#sign_y").attr("y",image.y)
        $(".imgFirma").addClass("hide");
        window.firma_desposicionada = false;
        if (image.x < 1 || image.y < 1 || image.x + width_rectangle > canvas.width - 1 || image.y > canvas.height - 1 - height_rectangle) {
            g.fillStyle = 'rgba(255, 0, 0, 0.3)'; // 30% opacity red
            g.fillRect(image.x, image.y, width_rectangle, height_rectangle); // Draw this over top of your image
            window.firma_desposicionada = true;
        } else {
            window.firma_desposicionada = false;
        }
    }


    var image = {
        url: "",
        x: 0,
        y: 0,
        x_escalado: 0,
        y_escalado: 0
    };

    if(zxx > -1){
        image.url = $(".imgFirma").attr("src");
        image.x = Math.floor(zxx) * (1 * scale);
        image.y = canvas.height - (Math.floor(zxy) * (1 * scale)) - height_rectangle;
        image.x_escalado = Math.floor(image.x) * (1 / scale);
        image.y_escalado = Math.floor(canvas.height  - image.y - height_rectangle)* (1 / scale);
        drawCanvas();
    }

    function drawCanvas() {
        var pdfImg = new Image();
        pdfImg.src = data;
        //g.clearRect(0, 0, canvas.width, canvas.height);

        if(zxx > -1){
            setTimeout(() => {

                g.drawImage(pdfImg, 0, 0);
                var img = new Image();
                img.src = image.url;
                img.width = width_rectangle;
                img.height = height_rectangle;
                setTimeout(() =>  g.drawImage(img, image.x, image.y, width_rectangle, height_rectangle),100)

                window.firma_desposicionada = false;
                if (image.x < 1 || image.y < 1 || image.x + width_rectangle > canvas.width - 1 || image.y > canvas.height - 1 - height_rectangle) {
                    g.fillStyle = 'rgba(255, 0, 0, 0.3)'; // 30% opacity red
                    g.fillRect(image.x, image.y, width_rectangle, height_rectangle); // Draw this over top of your image
                    window.firma_desposicionada = true;
                } else {
                    window.firma_desposicionada = false;
                }
            },140)
        }
        else{
            g.drawImage(pdfImg, 0, 0);
            var img = new Image();
            img.src = image.url;
            img.width = width_rectangle;
            img.height = height_rectangle;
            g.drawImage(img, image.x, image.y, width_rectangle, height_rectangle)
            window.firma_desposicionada = false;
            if (image.x < 1 || image.y < 1 || image.x + width_rectangle > canvas.width - 1 || image.y > canvas.height - 1 - height_rectangle) {
                g.fillStyle = 'rgba(255, 0, 0, 0.3)'; // 30% opacity red
                g.fillRect(image.x, image.y, width_rectangle, height_rectangle); // Draw this over top of your image
                window.firma_desposicionada = true;
            } else {
                window.firma_desposicionada = false;
            }
        }






    }

    function imageHit(x, y) {
        return (x >= image.x && x <= (image.x ) + width_rectangle && y >= image.y  && y - height_rectangle <= image.y);
    }

    function handleMouseDown(e) {
        e.preventDefault();

        var rect = canvas.getBoundingClientRect(),
            offsetX = rect.left,
            offsetY = rect.top;
        mouseX = parseInt(e.clientX - offsetX) ;
        mouseY = parseInt(e.clientY - offsetY);
        startX = mouseX;
        startY = mouseY;
        if (imageHit(mouseX, mouseY)) {
            canvas.style.cursor = "move";
        } else {
            canvas.style.cursor = "default"
        }
        // Put your mousedown stuff here

        if (imageHit(mouseX, mouseY)) {
            selectedImage = image;
        }

    }

// done dragging
    function handleMouseUp(e) {
        e.preventDefault();
        selectedImage = null;
    }

// also done dragging
    function handleMouseOut(e) {
        e.preventDefault();
        selectedImage = null;
    }

    function handleMouseMove(e) {
        var rect = canvas.getBoundingClientRect(),
            offsetX = rect.left,
            offsetY = rect.top;
        mouseX = parseInt(e.clientX - offsetX);
        mouseY = parseInt(e.clientY - offsetY);
        if (imageHit(mouseX, mouseY)) {
            canvas.style.cursor = "move";


        } else {
            canvas.style.cursor = "default"
        }

        if (selectedImage == null) {
            return;
        }

        e.preventDefault();


        // Put your mousemove stuff here
        var dx = mouseX - startX;
        var dy = mouseY - startY;
        startX = mouseX;
        startY = mouseY;

        var image = selectedImage;
        image.x += dx;
        image.y += dy;
        image.x_escalado = Math.floor(image.x) * (1 / scale);
        image.y_escalado = Math.floor(canvas.height - image.y - height_rectangle) * (1 / scale);
        $("#sign_x").val(image.x_escalado)
        $("#sign_y").val(image.y_escalado)
        //console.log(image)
        drawCanvas();
    }

    if(zxx == -1){
        canvas.addEventListener('mousemove', function (e) {
            handleMouseMove(e);
        }, false);

        canvas.addEventListener('mousedown', function (e) {
            handleMouseDown(e)
        }, false);
        canvas.addEventListener('mouseup', function (e) {
            handleMouseUp(e)
        }, false);
        canvas.addEventListener('mouseout', function (e) {
            handleMouseOut(e)
        }, false);


        // Add touch event support for mobile
        canvas.addEventListener("touchstart", function (e) {

        }, false);

        canvas.addEventListener("touchmove", function (e) {
            var touch = e.touches[0];
            var me = new MouseEvent("mousemove", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            canvas.dispatchEvent(me);
        }, false);

        canvas.addEventListener("touchstart", function (e) {
            //mousePos = getTouchPos(canvas, e);
            var touch = e.touches[0];
            var me = new MouseEvent("mousedown", {
                clientX: touch.clientX,
                clientY: touch.clientY
            });
            canvas.dispatchEvent(me);
        }, false);

        canvas.addEventListener("touchend", function (e) {
            var me = new MouseEvent("mouseup", {});
            canvas.dispatchEvent(me);
        }, false);


        document.body.addEventListener("touchstart", function (e) {
            if (e.target == canvas) {
                if(canvas.style.cursor == "move")
                    e.preventDefault();
            }
        }, {passive: false});
        document.body.addEventListener("touchend", function (e) {
            if (e.target == canvas) {
                if(canvas.style.cursor == "move")
                    e.preventDefault();
            }
        }, {passive: false});
        document.body.addEventListener("touchmove", function (e) {
            if (e.target == canvas) {
                if(canvas.style.cursor == "move")
                    e.preventDefault();
            }
        }, {passive: false});
    }


}
//
// window.render_all_pdf = function(url, token) {
//
//     var frame = $("#preview-document-" + token);
//     frame.attr("src", url);
//     $("#content-document-" + token).show();
//
// }
//



